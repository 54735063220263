import { boolean, defineAction, object, string } from '~/prix'
import synchronizeAppSebraeAction from './synchronizeAppSebrae.action.json'

const synchronizeAppSebraeDefinition = defineAction({
  ...synchronizeAppSebraeAction,
  input: object({
    id: string(),
    force: boolean(),
    verbose: boolean(),
    onlyMissing: boolean(),
    skipInternalGeocoding: boolean(),
  }),
  output: object({
    ok: boolean(),
  }),
})

export default synchronizeAppSebraeDefinition
